import Link from 'next/link';

import { Button } from 'src/components/ui/Button';
import { Lia } from 'src/components/ui/Lia';
import { Text } from 'src/components/ui/Text';
import { Title } from 'src/components/ui/Title';

export default function Page500() {
  return (
    <div className="flex h-screen items-center justify-center bg-accent-back p-4 sm:p-28">
      <div className="max-w-sm rounded bg-white p-5 shadow-md dark:bg-black sm:p-10">
        <Lia />

        <Title
          size="xs"
          className="mt-8 text-foreground-subtle"
        >
          Erro 500
        </Title>
        <Title
          size="lg"
          className="my-2"
        >
          Algo deu errado
        </Title>
        <Text>
          Não foi possível completar a ação desejada, tente novamente mais
          tarde.
        </Text>

        <Button
          variant="outline"
          size="sm"
          as={Link}
          href="https://lia.com.br"
          className="mt-8"
        >
          Ir para o início
        </Button>
      </div>
    </div>
  );
}
