import { GoogleTagManager } from '@next/third-parties/google';
import { Provider as RollbarProvider } from '@rollbar/react';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
import localFont from 'next/font/local';
import Head from 'next/head';

import { useRouter } from 'next/router';
import { useEffect } from 'react';
import 'src/styles/globals.css';

import dynamic from 'next/dynamic';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { Toaster } from 'src/components/ui/Toast/Toaster';
import AmplitudeProvider from 'src/providers/AmplitudeProvider';
import Page500 from './500.page';

const CookieConsent = dynamic(() => import('src/components/CookieConsent'), {
  ssr: false,
});

const inter = localFont({
  src: [
    {
      path: './../../public/inter/Inter-roman.var.woff2',
      weight: '100 900',
      style: 'normal',
    },
    {
      path: './../../public/inter/Inter-italic.var.woff2',
      weight: '100 900',
      style: 'italic',
    },
  ],
});

const rollbarConfig = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN,
  environment: 'production',
  captureUncaught: true,
  captureUnhandledRejections: true,
  scrubFields: ['cardNumber', 'cvv'],
  ignoredMessages: [
    '^"Timeout.{0,4}"$',
    '^Object Not Found Matching.*$',
    'Load failed',
    'Script error',
    'Failed to fetch',
    "Unexpected token '<'",
    'ResizeObserver loop completed with undelivered notifications.',
    'Performance.measure: Given mark name, beforeRender, is unknown',
    "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
    'Minified React error #423',
    'Minified React error #425',
    'Minified React error #418',
  ],
  payload: {
    client: {
      javascript: {
        code_version: process.env.NEXT_PUBLIC_APP_VERSION,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

export default function App({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter();
  const palette = pageProps.palette || 'navy';

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <>
      <Head>
        <title>Lia - Multiplicando oportunidades</title>

        <meta
          name="description"
          content="Multiplicando oportunidades de acesso à educação com apoio financeiro descomplicado."
        />

        <meta
          name="viewport"
          content="initial-scale=1, width=device-width"
        />

        <link
          rel="preconnect"
          href="https://www.google.com"
        />

        <link
          rel="preconnect"
          href="https://www.gstatic.com"
        />

        {Boolean(pageProps.pixelCode) && (
          <>
            <script id="pixel">
              {`!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;t.crossOrigin='anonymous';s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${pageProps.pixelCode}');
  fbq('track', 'PageView');`}
            </script>
            <noscript>
              <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                src={`https://www.facebook.com/tr?id=${pageProps.pixelCode}&ev=PageView&noscript=1`}
                alt="pixel"
              />
            </noscript>
          </>
        )}
      </Head>
      <style
        jsx
        global
      >{`
        :root {
          --font-sans: ${inter.style.fontFamily};

          --accent-main: var(--${palette}-600);
          --accent-most: var(--${palette}-800);
          --accent-least: var(--${palette}-400);
          --accent-alpha: var(--${palette}-600);
          --accent-back: var(--${palette}-100);
        }

        .dark {
          --accent-main: var(--${palette}-600);
          --accent-most: var(--${palette}-300);
          --accent-least: var(--${palette}-700);
          --accent-alpha: var(--${palette}-600);
          --accent-back: var(--${palette}-950);
        }
      `}</style>

      <AmplitudeProvider>
        <RollbarProvider config={rollbarConfig}>
          <ErrorBoundary fallbackUI={Page500}>
            <ThemeProvider
              attribute="class"
              defaultTheme="light"
              enableSystem={false}
            >
              <Component {...pageProps} />
              <Toaster />
              <CookieConsent />
            </ThemeProvider>
          </ErrorBoundary>
        </RollbarProvider>
      </AmplitudeProvider>
      {process.env.NEXT_PUBLIC_GTM_CONTAINER && (
        <GoogleTagManager
          gtmId={process.env.NEXT_PUBLIC_GTM_CONTAINER}
          dataLayerName="liaDataLayer"
        />
      )}

      {pageProps.googlePixelCode && (
        <GoogleTagManager gtmId={pageProps.googlePixelCode} />
      )}
    </>
  );
}
