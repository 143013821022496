import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from 'src/utils/tailwind';

const titleVariants = cva(
  'font-sans font-disambiguation font-bold leading-tight text-foreground-main tracking-tight',
  {
    variants: {
      size: {
        xs: 'text-xs leading-none tracking-wider uppercase',
        sm: 'text-base',
        md: 'text-xl',
        lg: 'text-2xl',
        xl: 'text-3xl',
        '2xl': 'text-4xl',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

export interface TitleProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof titleVariants> {
  as?: React.ElementType;
}

const Title = React.forwardRef<HTMLElement, TitleProps>(
  ({ className, size, as, ...props }, ref) => {
    const Component = as || 'p';

    return (
      <Component
        className={cn(titleVariants({ className, size }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Title.displayName = 'Title';

export { Title };
