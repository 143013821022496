import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from 'src/utils/tailwind';

const iconVariants = cva('', {
  variants: {
    size: {
      '2xs': 'text-[8px] h-2 w-2',
      xs: 'text-[12px] h-3 w-3',
      sm: 'text-[14px] h-3.5 w-3.5',
      md: 'text-[16px] h-4 w-4',
      lg: 'text-[24px] h-5 w-5',
      xl: 'text-[48px] h-12 w-12',
      xxl: 'text-[56px] h-14 w-14',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export interface IconProps
  extends Omit<FontAwesomeIconProps, 'size'>,
    VariantProps<typeof iconVariants> {
  as?: React.ElementType;
  icon: FontAwesomeIconProps['icon'];
}

const Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ icon, className, size, as, ...props }, ref) => (
    <FontAwesomeIcon
      ref={ref}
      icon={icon}
      className={cn(iconVariants({ className, size }))}
      {...props}
    />
  ),
);

Icon.displayName = 'Icon';

export { Icon };
