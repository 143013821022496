import * as React from 'react';

import { cn } from 'src/utils/tailwind';

export interface LiaProps extends React.SVGAttributes<SVGElement> {
  variant?: 'color' | 'mono';
}

const Lia = ({ className, variant }: LiaProps) => {
  variant = variant || 'color';

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="24"
      fill="none"
      viewBox="0 0 36 24"
    >
      <path
        className={cn({
          'fill-navy-600': variant === 'color',
          'fill-coal-400': variant === 'mono',
        })}
        d="M30.179.488a1.667 1.667 0 0 0-2.357 2.357l1.488 1.488-1.488 1.488a1.667 1.667 0 0 0 2.357 2.358l1.488-1.489 1.488 1.489a1.667 1.667 0 1 0 2.357-2.358l-1.488-1.488 1.488-1.488A1.667 1.667 0 1 0 33.155.488l-1.488 1.488L30.179.488Z"
      />
      <path
        className={cn({
          'fill-coal-800': variant === 'color',
          'fill-coal-400': variant === 'mono',
        })}
        d="M2.667 1.667H0v18C0 21.667 0 24 4.667 24v-2.333c-2 0-2-1.334-2-2v-18ZM10.833 7.667H8.167v16h2.666v-16Z"
      />
      <path
        className={cn({
          'fill-coal-800': variant === 'color',
          'fill-coal-400': variant === 'mono',
        })}
        fillRule="evenodd"
        d="M16 7.667h4.667c6 0 6 6 6 6v10H24V22.16c-.582.685-2.05 1.84-5.333 1.84C14 24 14 19.667 14 19.667c0-2 .667-4 4.667-4.834l5.333-.94v-.226s-.015-3.334-3.333-3.334H16V7.667Zm3.333 14c4.571 0 4.665-3.837 4.667-3.995v-1.364L20 17c-3.333.583-3.333 2-3.333 2.667 0 0 0 2 2.666 2Z"
        clipRule="evenodd"
      />
      <path
        className={cn({
          'fill-coal-800': variant === 'color',
          'fill-coal-400': variant === 'mono',
        })}
        d="M9.5 6.333a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      />
    </svg>
  );
};

Lia.displayName = 'Lia';

export { Lia };
