import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from 'src/utils/tailwind';

const textVariants = cva(
  'font-sans leading-normal no-underline text-foreground-main',
  {
    variants: {
      weight: {
        normal: 'font-normal',
        medium: 'font-medium',
        bold: 'font-bold',
      },
      size: {
        sm: 'text-sm',
        md: 'text-base',
        lg: 'text-xl',
      },
      textStyle: {
        normal: 'not-italic',
        italic: 'italic',
      },
    },
    defaultVariants: {
      weight: 'normal',
      size: 'md',
      textStyle: 'normal',
    },
  },
);

export interface TextProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof textVariants> {
  as?: React.ElementType;
}

const Text = React.forwardRef<HTMLElement, TextProps>(
  ({ className, weight, size, textStyle, as, ...props }, ref) => {
    const Component = as || 'p';

    return (
      <Component
        className={cn(textVariants({ weight, className, size, textStyle }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Text.displayName = 'Text';

export { Text };
