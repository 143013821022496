import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

type AmplitudeParams = {
  userId?: number | string;
  userEmail?: string;
  companyId?: string;
  companySlug?: string;
  [key: string]: string | string[] | undefined | number;
};

type EventParams = {
  event: string;
  eventProperties?: AmplitudeParams;
};

type AmplitudeProviderContextType = {
  init: (amplitudeParams: AmplitudeParams) => void;
  addParams: (amplitudeParams: AmplitudeParams) => void;
  logEvent: (event: EventParams) => void;
};

export const AmplitudeProviderContext =
  createContext<AmplitudeProviderContextType>({
    init: () => {},
    addParams: () => {},
    logEvent: () => {},
  });

const AmplitudeProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [defaultParams, setDefaultParams] = useState<AmplitudeParams>({});
  const [initialized, setInitialized] = useState<boolean>(false);
  const [logEventQueue, setLogEventQueue] = useState<Array<EventParams>>([]);

  useEffect(() => {
    logEventQueue.forEach((event) => {
      logEvent(event);
    });
  }, [initialized]);

  const init = (defaultParams: AmplitudeParams) => {
    if (initialized) return;

    setInitialized(true);
    addParams(defaultParams);
  };

  const addParams = (params: AmplitudeParams) => {
    setDefaultParams({ ...defaultParams, ...params });
  };

  const logEvent = ({ event, eventProperties = {} }: EventParams) => {
    if (!initialized) {
      setLogEventQueue((queue) => [...queue, { event, eventProperties }]);
      return;
    }

    const eventData = {
      user_id: defaultParams.userId,
      event_type: event,
      event_properties: eventProperties,
      user_properties: {
        companySlug: defaultParams.companySlug,
        companyId: defaultParams.companyId,
        userEmail: defaultParams.userEmail,
      },
    };

    if (process.env.NODE_ENV !== 'production') {
      console.log('An event will be logged with params:', eventData);
      return;
    }

    const ajax = new XMLHttpRequest();

    ajax.open('POST', 'https://api.amplitude.com/2/httpapi', true);
    ajax.setRequestHeader('Content-type', 'application/json');

    ajax.send(
      JSON.stringify({
        api_key: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
        events: [eventData],
        options: {
          min_id_length: 1,
        },
      }),
    );
  };

  const value = {
    init,
    addParams,
    logEvent,
  };

  return (
    <AmplitudeProviderContext.Provider value={value}>
      {children}
    </AmplitudeProviderContext.Provider>
  );
};

export const useAmplitude = () => {
  const context = useContext(AmplitudeProviderContext);

  return context;
};

export default AmplitudeProvider;
