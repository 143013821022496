import {
  Context,
  ContextInterface,
  getRollbarFromContext,
  LEVEL_ERROR,
} from '@rollbar/react';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Callback } from 'rollbar';

const INITIAL_ERROR_STATE = { hasError: false, error: null };

type Extra = Record<string | number, unknown>;

export interface ErrorBoundaryProps {
  children: ReactNode;
  fallbackUI?: React.ElementType;
  errorMessage?: string | (() => string);
  extra?: Extra | ((error: Error, errorInfo: ErrorInfo) => Extra);
  callback?: Callback<any>;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

function value(val, ...args) {
  if (typeof val === 'function') {
    return val(...args);
  }
  return val;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static contextType = Context;

  static defaultProps = {
    level: LEVEL_ERROR,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_ERROR_STATE };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    const { errorMessage, extra, callback } = this.props;
    const custom = value(extra, error, info);
    const data = { ...info, ...custom };
    const level = value(LEVEL_ERROR, error, info);
    const rollbar = getRollbarFromContext(
      this.context as React.Context<ContextInterface>,
    );

    if (!errorMessage) {
      if (rollbar) {
        rollbar[level](error, data, callback);
        return;
      }

      console.error(error, data, callback);
    } else {
      let logMessage = value(errorMessage, error, info);

      if (rollbar) {
        rollbar[level](logMessage, error, data, callback);
        return;
      }

      console.error(logMessage, error, data, callback);
    }
  }

  resetError = () => {
    this.setState(INITIAL_ERROR_STATE);
  };

  render() {
    const { hasError, error } = this.state;
    const { fallbackUI: FallbackUI, children } = this.props;

    if (!hasError) {
      return children;
    }

    if (!FallbackUI) {
      return null;
    }

    return (
      <FallbackUI
        error={error}
        resetError={this.resetError}
      />
    );
  }
}
