import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/sharp-solid-svg-icons';
import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from 'src/utils/tailwind';
import { Icon, IconProps } from '../Icon';

const buttonVariants = cva(
  'relative rounded-lg inline-flex font-sans font-medium not-italic no-underline inline-flex items-center justify-center transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none',
  {
    variants: {
      variant: {
        solid: 'bg-accent-main hover:bg-accent-most text-foreground-inverted',
        outline:
          'bg-background-main hover:bg-background-least text-foreground-main border border-foreground-least',
        ghost: 'hover:bg-background-alpha underline text-foreground-subtle',
        destructive: 'bg-negative-main hover:bg-negative-most text-white',
      },
      size: {
        sm: 'text-sm leading-5 py-1.5 px-3',
        md: 'text-base leading-6 py-2 px-4',
        lg: 'text-xl leading-7 py-2.5 px-5',
      },
      isLoading: {
        true: 'opacity-50 text-transparent',
        false: '',
      },
      iconOnly: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      // Sizing for loading buttons
      {
        size: 'sm',
        isLoading: true,
        iconOnly: true,
        className: 'w-8 px-2',
      },
      {
        size: 'md',
        isLoading: true,
        iconOnly: true,
        className: 'w-11 px-2.5',
      },
      {
        size: 'lg',
        isLoading: true,
        iconOnly: true,
        className: 'w-12 px-3',
      },

      // Sizing for icon only buttons
      {
        size: 'sm',
        iconOnly: true,
        className: 'w-8',
      },
      {
        size: 'md',
        iconOnly: true,
        className: 'w-11',
      },
      {
        size: 'lg',
        iconOnly: true,
        className: 'w-12',
      },
    ],
    defaultVariants: {
      variant: 'solid',
      size: 'md',
      isLoading: false,
    },
  },
);

const buttonIconVariants = cva('', {
  variants: {
    variant: {
      solid: 'text-white',
      outline: 'text-foreground-main',
      ghost: 'text-foreground-subtle',
      destructive: 'text-white',
      positive: 'text-white',
    },
    iconSize: {
      xs: 'w-4 h-4',
      sm: '',
      md: '',
      lg: 'w-6 h-6',
    },
    isLoading: {
      true: 'absolute animate-spin',
      false: '',
    },
    iconOnly: {
      true: '',
      false: 'mr-2',
    },
  },
  defaultVariants: {
    variant: 'solid',
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  loading?: boolean;
  icon?: FontAwesomeIconProps['icon'];
  as?: React.ElementType;
  href?: string;
  target?: HTMLAnchorElement['target'];
  iconSize?: IconProps['size'];
}

export interface ButtonIconProps
  extends IconProps,
    VariantProps<typeof buttonIconVariants> {
  isLoading?: boolean;
}

const ButtonIcon = React.forwardRef<SVGSVGElement, ButtonIconProps>(
  ({ className, variant, isLoading, icon, spin, iconOnly, ...props }, ref) => {
    return (
      <Icon
        className={cn(
          buttonIconVariants({ variant, className, isLoading, iconOnly }),
        )}
        icon={isLoading ? faSpinnerThird : icon}
        ref={ref}
        {...props}
      />
    );
  },
);

ButtonIcon.displayName = 'ButtonIcon';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      isLoading,
      icon,
      children,
      as,
      iconSize,
      ...props
    },
    ref,
  ) => {
    const Component = as || 'button';
    return (
      <Component
        className={cn(
          buttonVariants({
            variant,
            size,
            className,
            isLoading,
            iconOnly: (icon || isLoading) && !children,
          }),
          className,
        )}
        ref={ref}
        disabled={props.disabled || isLoading}
        {...props}
      >
        {(isLoading || icon) && (
          <ButtonIcon
            variant={variant}
            size={iconSize || 'md'}
            icon={isLoading ? faSpinnerThird : icon}
            isLoading={isLoading}
            iconOnly={icon && !children}
          />
        )}
        {children}
      </Component>
    );
  },
);

Button.displayName = 'Button';

export { Button };
